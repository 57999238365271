import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    resultModalUrl: String,
    variables: Object,
    autoFill: Boolean,
    minimumSearchLength: Number,
    followLinkOnEnter: Boolean,
    focusedResultIndex: Number,
    clearInputWhenSearch: Boolean,
    autofocus: Boolean,
    channelsUrl: String,
  };
  static targets = [
    "search",
    "template",
    "id",
    "className",
    "resultsContainer",
    "container",
    "searchResponseContainer",
    "postResponsesContainer",
    "clearIcon",
  ];

  initialize() {
    this.debounceSearchTargetKeyup = debounce(this.searchTargetKeyup, 150).bind(this);
  }

  connect() {
    this.windowParams = new URLSearchParams(window.location.search);
    this.loadChannelData();
    this.searchResultTemplate = this.templateTarget.innerHTML;
    this.element.classList.add("relative");

    // Add an event listener for focus to toggle the CSS class
    this.searchTarget.addEventListener("focus", () => {
      this.searchTarget.classList.add("focused");
    });

    // Add an event listener for blur to remove the CSS class when focus is lost
    this.searchTarget.addEventListener("blur", () => {
      setTimeout(() => {
        this.searchTarget.classList.remove("focused");
        this.hideInnerResults();
      }, 400);
    });

    if (this.hasAutofocusValue && this.autofocusValue) {
      this.focusInputAtEnd();
    }

    if (!this.hasMinimumSearchLengthValue) {
      this.minimumSearchLengthValue = 3;
    }

    this.searchResults = [];
    this.searchTarget.addEventListener("keyup", this.debounceSearchTargetKeyup);
    this.searchTarget.addEventListener("focus", this.maybeAutoSearch.bind(this));

    if (this.windowParams.has("query_string") && this.searchTarget.value !== this.windowParams.get("query_string")) {
      this.searchTarget.value = this.windowParams.get("query_string");
      this.searchTargetKeyup({ keyCode: 13 });
      this.windowParams.delete("query_string");
      let url = window.location.href.split("?")[0];
      if (this.windowParams.toString() !== "") {
        url += `?${this.windowParams.toString()}`;
      }
      window.history.replaceState({}, "", url);
    }
  }

  async loadChannelData() {
    const res = await get(this.channelsUrlValue, { responseKind: "json" });
    this.availableChannels = await res.json;
  }

  disconnect() {
    this.searchTarget.removeEventListener("keyup", this.debounceSearchTargetKeyup);
    this.searchTarget.removeEventListener("focus", this.maybeAutoSearch.bind(this));
  }

  createSearchResult(result, forListing = true) {
    //searchResultTemplate is a string, so adding an empty string will create a new string
    let resultContent = this.searchResultTemplate + "";
    for (const variable in this.variablesValue) {
      resultContent = resultContent.replace(`{{${variable}}}`, result[this.variablesValue[variable]]);
    }

    const resultElement = document.createElement("div");
    resultElement.innerHTML = resultContent;
    resultElement.addEventListener("click", this.selectSearchResultOnClick.bind(this));

    if (forListing) {
      resultElement.classList.add(
        "result-item",
        "flex",
        "items-center",
        "gap-2",
        "px-2",
        "hover:bg-gray-50",
        "rounded",
        "cursor-pointer",
        "text-dark-600"
      );
      resultElement.dataset.id = `${result.id}`;
      resultElement.dataset.class_name = result.class_name;
      resultElement.dataset.isResult = true;
    } else {
      resultElement.classList.add("flex-grow", "bg-dark-50", "rounded");
    }

    return resultElement;
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find((result) => {
      return (
        `${result.id}` === `${ev.currentTarget.dataset.id}` && result.class_name === ev.currentTarget.dataset.class_name
      );
    });

    if (selectedResult != undefined) {
      this.searchTarget.value = selectedResult.name;
    }
    this.hideInnerResults();
    this.showPostResponses();
    this.potentiallyReset();
    this.searchTarget.classList.remove("focused");
  }

  maybeAutoSearch() {
    if (this.autoFillValue) {
      this.searchTargetKeyup({ keyCode: 0 });
    }
  }

  hideInnerResults() {
    this.resultsContainerTarget.classList.add("hidden");
  }

  hideSearchResults() {
    this.searchResponseContainerTarget.classList.add("hidden");
  }

  hidePostResponses() {
    if (this.hasPostResponsesContainerTarget) {
      this.postResponsesContainerTarget.classList.add("hidden");
    }
  }

  showPostResponses() {
    if (this.hasPostResponsesContainerTarget) {
      this.postResponsesContainerTarget.classList.remove("hidden");
    }
  }

  unhideSearchResults() {
    // If more than one results containers, assume the parent should be shown
    if (this.resultsContainerTargets.length > 1) {
      this.resultsContainerTarget.parentElement.classList.remove("hidden");
    } else {
      this.resultsContainerTarget.classList.remove("hidden");
    }
    this.searchResponseContainerTarget.classList.remove("hidden");
  }

  searchTargetKeyup(e) {
    if (e.keyCode === 38) {
      // up arrow
      this.focusPreviousResult();
      return false;
    }
    if (e.keyCode === 40) {
      // down arrow
      this.focusNextResult();
      return;
    }

    this.searchString = this.searchTarget.value.trim();
    const minimumSearchLengthMet = this.searchString.length >= this.minimumSearchLengthValue;

    if (e.keyCode === 13 && minimumSearchLengthMet) {
      // enter
      this.handleEnterPress();
    }

    const ignoredKeyModifier =
      e.keyCode === 13 ||
      e.keyCode === 16 ||
      e.keyCode === 17 ||
      e.keyCode === 18 ||
      e.keyCode === 37 ||
      e.keyCode === 39;

    this.clearIconTarget.classList.remove("hidden");

    const data =
      this.searchTarget.dataset.queryParam &&
      `search=${this.searchTarget.dataset.queryParam}&${this.searchTarget.dataset.queryParam}=${encodeURIComponent(
        this.searchString
      )}&exclude_id=${this.searchTarget.dataset.excludeId}`;

    if (minimumSearchLengthMet && !ignoredKeyModifier) {
      const match = this.searchTarget.value.match(/(^|.*\s)in:(.+)/);
      if (match) {
        this.displayChannelSuggestions(match[1].trim(), match[2]);
      } else {
        Rails.ajax({
          type: "GET",
          url: this.urlValue,
          data: data,
          error: this.showEmptySearchResults.bind(this),
          success: this.showSearchResults.bind(this),
        });
      }
    }
    if (this.searchString.length == 0) {
      this.handleEmptySearchString();
    }
  }

  async displayChannelSuggestions(searchText, channelPrefix) {
    if (!this.availableChannels) {
      const response = await fetch(this.channelsUrlValue, { responseKind: "json" });
      this.availableChannels = await response.json();
    }

    const channelsArray = Object.entries(this.availableChannels).map(([id, name]) => ({ id, name }));
    const filteredChannels =
      channelPrefix.length > 0
        ? channelsArray.filter((channel) => channel.name.toLowerCase().includes(channelPrefix.toLowerCase()))
        : channelsArray;

    let results = [];
    filteredChannels.forEach((channel) => {
      const text = `${searchText} <span class="bg-dark-300 rounded px-2 py-1">in: ${channel.name}</span>`;
      results.push({
        id: -1,
        class_name: "",
        link: `${this.resultModalUrlValue}?query_string=${encodeURIComponent(searchText)}&channel_id=${channel.id}`,
        name: text,
      });
    });
    this.updateSearchResults(results);
  }

  handleEnterPress() {
    if (this.searchString == undefined || this.searchString.length == 0) {
      this.handleEmptySearchString();
      return;
    }
    if (this.currentlyFocusedResultElement && this.focusedResultIndexValue >= 0) {
      this.currentlyFocusedResultElement.querySelector("a").click();
    } else {
      get(`${this.resultModalUrlValue}?query_string=${encodeURIComponent(this.searchString)}`, {
        responseKind: "turbo-stream",
      });
      this.windowParams.set("query_string", this.searchString);
      window.history.replaceState({}, "", `${window.location.href.split("?")[0]}?${this.windowParams.toString()}`);
    }

    this.hideInnerResults();
    this.potentiallyReset();
  }

  clearSearchBar() {
    this.searchTarget.value = "";
    this.handleEmptySearchString();
  }

  handleEmptySearchString() {
    this.clearIconTarget.classList.add("hidden");

    this.searchTarget.readOnly = false;
    this.searchTarget.value = "";

    this.idTargets.forEach((idTarget) => {
      idTarget.value = null;
      this.triggerChangeEvent(idTarget);
    });
    this.hideInnerResults();

    if (this.hasClassNameTarget) {
      this.classNameTarget.value = undefined;
    }
  }

  reset() {
    this.searchTarget.readOnly = false;
    this.searchTarget.value = "";
    this.searchString = "";

    this.idTargets.forEach((idTarget) => {
      idTarget.value = null;
      this.triggerChangeEvent(idTarget);
    });
    this.hideSearchResults();
    this.hidePostResponses();

    if (this.hasClassNameTarget) {
      this.classNameTarget.value = undefined;
    }
  }

  triggerChangeEvent(element) {
    const event = new Event("change");
    element.dispatchEvent(event);
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  showSearchResults(results) {
    this.updateSearchResults(results);
  }

  focusPreviousResult() {
    this.focusedResultIndexValue =
      this.focusedResultIndexValue > 0 ? this.focusedResultIndexValue - 1 : this.searchResults.length - 1;

    this.focusResult();
  }

  focusNextResult() {
    this.focusedResultIndexValue =
      this.focusedResultIndexValue < this.searchResults.length - 1 ? this.focusedResultIndexValue + 1 : 0;

    this.focusResult();
  }

  focusResult() {
    var runningIndex = 0;

    if (this.focusedResultIndexValue < 0) {
      return;
    }

    this.resultsContainerTargets.forEach((resultsContainerTarget) => {
      resultsContainerTarget.querySelectorAll("div.result-item").forEach((result) => {
        if (runningIndex === this.focusedResultIndexValue) {
          this.currentlyFocusedResultElement = result;
          result.classList.add("bg-gray-100", "hover:!bg-gray-100");

          // If more than one resultsContainerTargets, scroll the grandparent
          const parentElement =
            this.resultsContainerTargets.length > 1 ? result.parentElement.parentElement : result.parentElement;

          parentElement.scroll({
            top: result.offsetTop - 40,
            behavior: "smooth",
          });
        } else {
          result.classList.remove("bg-gray-100", "hover:!bg-gray-100");
        }

        runningIndex++;
      });
    });
  }

  updateSearchResults(results) {
    this.searchResults = results;
    this.focusedResultIndexValue = -1;

    this.resultsContainerTargets.forEach((resultsContainer) => {
      resultsContainer.querySelectorAll("div").forEach((result) => {
        result.remove();
      });
    });

    if (results.length > 0) {
      this.unhideSearchResults();
      results.forEach((result) => {
        const resultsContainer = this.resultsContainerForResult(result);

        resultsContainer.insertAdjacentElement("beforeend", this.createSearchResult(result));
      });
      this.focusResult();
    }
  }

  resultsContainerForResult(result) {
    const resultsContainer = this.resultsContainerTargets.find((resultsContainer) => {
      const classification = resultsContainer.dataset.classification;
      const classifyColumn = resultsContainer.dataset.classifyColumn || "class_name";

      return result[classifyColumn] === classification;
    });

    return resultsContainer || this.resultsContainerTarget;
  }

  potentiallyReset() {
    if (this.hasClearInputWhenSearchValue && this.clearInputWhenSearchValue == true) {
      this.reset();
    }
  }

  focusInputAtEnd() {
    this.searchTarget.focus();
    this.searchTarget.setSelectionRange(this.searchTarget.value.length, this.searchTarget.value.length);
  }
}
