function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) {
      func.apply(context, args);
    }
  };
}

function delay(func, wait, ...args) {
  return setTimeout(() => {
    func.apply(null, args);
  }, wait);
}

function throttle(func, wait) {
  let timeout = null;
  let lastExecution = 0;

  return function () {
    const context = this;
    const args = arguments;
    const now = Date.now();

    if (!lastExecution) {
      lastExecution = now;
    }

    const remainingWait = wait - (now - lastExecution);

    if (remainingWait <= 0 || remainingWait > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      lastExecution = now;
      func.apply(context, args);
    } else if (!timeout) {
      timeout = setTimeout(() => {
        lastExecution = Date.now();
        timeout = null;
        func.apply(context, args);
      }, remainingWait);
    }
  };
}

function uniq(array) {
  return [...new Set(array)];
}

function reduce(array, reducer, initialValue) {
  let accumulator = initialValue;

  for (let item of array) {
    accumulator = reducer(accumulator, item);
  }

  return accumulator;
}

function includes(container, value) {
  if (container === null || container === undefined) {
    return false;
  }

  return container.indexOf(value) !== -1;
}

function filter(array, predicate) {
  const result = [];
  for (const item of array) {
    if (predicate(item)) {
      result.push(item);
    }
  }
  return result;
}

export { debounce, delay, throttle, uniq, reduce, includes, filter };
