import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["section", "link"];
  static values = { activeClass: String };

  connect() {
    this.activeClassValue = this.activeClassValue || "active";

    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: null,
      rootMargin: "-30% 0px -30% 0px",
      threshold: 0.1,
    });

    this.sectionTargets.forEach((section) => this.observer.observe(section));
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      const sectionId = entry.target.id;
      const menuLink = this.linkTargets.find((link) => link.getAttribute("href") === `#${sectionId}`);

      if (entry.isIntersecting) {
        this.clearActiveClasses();
        if (menuLink) {
          menuLink.classList.add(this.activeClassValue);
        }
      }
    });
  }

  clearActiveClasses() {
    this.linkTargets.forEach((link) => link.classList.remove(this.activeClassValue));
  }

  disconnect() {
    this.observer.disconnect();
  }
}
