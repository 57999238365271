import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:submit-end", this.handleSubmit);
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit);
  }

  handleSubmit = (e) => {
    const form = e.target;

    if (
      this.checkIfPreventCloseCookiePresent(e) ||
      (this.preventModalClose() && this.preventModalClose().value === "true") ||
      form.dataset.preventModalClose
    ) {
      return;
    } else if (e.detail.success) {
      this.close();
    }
  };

  close() {
    this.modalTurboFrame().removeAttribute("src");
    this.element.innerHTML = "";
    this.disconnect();
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }

  modalTurboFrame() {
    return document.querySelector("turbo-frame[id='turbo-modal']");
  }

  preventModalClose() {
    return document.querySelector("#prevent_modal_close");
  }

  checkIfPreventCloseCookiePresent(e) {
    return e?.detail?.fetchResponse?.response?.headers?.get("X-Prevent-Modal-Close") === "true";
  }
}
