import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["data", "showIcon", "hideIcon"];
  static values = { showLastCharacters: Number };

  connect() {
    this.originalData = this.dataTarget.innerHTML.trim();
    this.hide();
  }

  toggle() {
    this.hidden ? this.reveal() : this.hide();
  }

  reveal() {
    this.hidden = false;
    this.dataTarget.innerHTML = this.originalData;
    this.showIconTarget.classList.add("!hidden");
    this.hideIconTarget.classList.remove("!hidden");
  }

  hide() {
    this.hidden = true;

    const showLastCharacters = this.showLastCharactersValue || 0;
    const maskedLength = this.originalData.length - showLastCharacters;
    const maskedData = "•".repeat(maskedLength) + this.originalData.slice(maskedLength);
    this.dataTarget.innerHTML = maskedData;

    this.showIconTarget.classList.remove("!hidden");
    this.hideIconTarget.classList.add("!hidden");
  }
}

