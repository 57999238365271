import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const iconContainer = this.addIcon();
    const iconElement = iconContainer.querySelector("i");
    this.iconElement = iconElement;
    iconElement.addEventListener("click", this.togglePassword.bind(this));
  }

  togglePassword() {
    if (this.element.type === "password") {
      this.element.type = "text";
      this.iconElement.classList.remove("ph-eye-bold");
      this.iconElement.classList.add("ph-eye-slash-bold");
    } else {
      this.element.type = "password";
      this.iconElement.classList.add("ph-eye-bold");
      this.iconElement.classList.remove("ph-eye-slash-bold");
    }
  }

  addIcon() {
    const spanElement = document.createElement("span");
    spanElement.classList.add("show-password-icon");
    spanElement.innerHTML = '<i class="ph-eye-bold" data-show-password-target="showPassword"></i>';
    spanElement.setAttribute("toggle", "#password-field");
    return this.element.insertAdjacentElement("afterend", spanElement);
  }
}
