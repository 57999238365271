import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    taxIdType: String,
    showLastFourDigits: { type: Boolean, default: true },
  };

  connect() {
    this.addEventListeners();
    this.setup();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener("input", this.format);
  }

  removeEventListeners() {
    this.element.removeEventListener("input", this.format);
  }

  setup() {
    this.setupOuterContainer();
    this.setupButton();
    this.setupMaskedText();
    this.toggleShowHide();
  }

  setupOuterContainer() {
    if (this.element.parentNode.dataset.element === "wrapper") return;

    this.outerContainer = document.createElement("div");
    this.outerContainer.dataset.element = "wrapper";
    this.outerContainer.classList.add("fieldset-icon-trail");
    this.outerContainer.innerHTML = this.element.outerHTML;
    this.element.outerHTML = this.outerContainer.outerHTML;
  }

  setupButton() {
    const fieldsetIconsDiv = document.createElement("div");
    fieldsetIconsDiv.classList.add("fieldset-icons");

    this.showHideButton = document.createElement("button");
    this.showHideButton.type = "button";
    this.showHideButton.classList.add("flex");
    this.showHideButton.classList.add("items-center");
    this.showHideButton.addEventListener("click", this.toggleShowHide.bind(this));
    this.setButtonIcon(true);
    fieldsetIconsDiv.appendChild(this.showHideButton);
    this.element.insertAdjacentElement("afterend", fieldsetIconsDiv);
  }

  setupMaskedText() {
    this.maskedTextContainer = document.createElement("div");
    this.maskedTextContainer.classList.add("!hidden");
    this.maskedTextContainer.classList.add("form-control");
    this.maskedTextContainer.setAttribute("data-controller", "tooltip");
    this.maskedTextContainer.setAttribute("data-tippy-content", "To change this value, first reveal the field.");
    this.maskedTextContainer.setAttribute("data-tippy-placement", "top-start");
    this.maskFieldValue();
    this.element.insertAdjacentElement("afterend", this.maskedTextContainer);
  }

  toggleShowHide() {
    this.maskFieldValue();

    if (this.element.value.length === 0) {
      this.showField();
      this.hideMaskedText();
    } else {
      this.toggleField();
      this.toggleMaskedText();
    }
  }

  maskFieldValue() {
    this.maskedTextContainer.innerHTML = this.element.value.replace(/./g, "•");

    if (this.maskedTextContainer.innerHTML.length > 4 && this.showLastFourDigitsValue) {
      const visiblePart = this.element.value.slice(-4);
      const maskedPart = this.element.value.slice(0, -4).replace(/./g, "•");
      this.maskedTextContainer.innerHTML = `<div>${maskedPart}</div><div>${visiblePart}</div>`;
    }
  }

  toggleField() {
    if (this.element.classList.contains("!hidden")) {
      this.showField();
    } else {
      this.hideField();
    }
  }

  toggleMaskedText() {
    if (this.maskedTextContainer.classList.contains("!hidden")) {
      this.showMaskedText();
    } else {
      this.hideMaskedText();
    }
  }

  hideField() {
    this.element.classList.add("!hidden");
    this.setButtonIcon(false);
  }

  showField() {
    this.element.classList.remove("!hidden");
    this.setButtonIcon(true);
  }

  hideMaskedText() {
    this.maskedTextContainer.classList.add("!hidden");
  }

  showMaskedText() {
    this.maskedTextContainer.classList.remove("!hidden");
  }

  setButtonIcon(visible) {
    this.showHideButton.innerHTML = this.buttonIcon(visible);
  }

  buttonIcon(visible) {
    const icon = visible ? "ph-eye-slash" : "ph-eye";

    return `<i class="${icon} text-lg text-gray-400 hover:text-gray-500"></i>`;
  }

  format = () => {
    if (this.taxIdTypeValue === "ssn") {
      this.formatSSN();
    } else if (this.taxIdTypeValue === "ein") {
      this.formatEIN();
    } else {
      this.formatSSN();
    }
  };

  formatSSN = () => {
    let value = this.element.value.replace(/\D/g, "");

    if (value.length > 3 && value.length <= 5) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    } else if (value.length > 5) {
      value = value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5, 9);
    }
    this.element.value = value;
  };

  formatEIN = () => {
    let value = this.element.value.replace(/\D/g, "");

    if (value.length > 2) {
      value = value.slice(0, 2) + "-" + value.slice(2);
    }

    this.element.value = value;
  };
}
