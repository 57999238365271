import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    pattern: String,
    message: String,
  }

  connect() {
    this.addEventListener();
    this.setInitialPattern();
  }

  disconnect() {
    this.removeEventListener();
  }

  addEventListener(){
    this.element.addEventListener("input", this.setValidityMessage.bind(this));
  }

  removeEventListener(){
    this.element.removeEventListener("input", this.setValidityMessage.bind(this));
  }

  setInitialPattern() {
    this.setInputValidation();
    this.setValidityMessage();
  }

  setInputValidation() {
    if (this.patternValue && this.patternValue.length) this.element.setAttribute("pattern", this.patternValue);
    this.patternValidityMessage = this.messageValue || "";
  }

  setValidityMessage() {
    if (this.element.validity.patternMismatch && this.patternValidityMessage.length) {
      this.element.setCustomValidity(this.patternValidityMessage);
    } else {
      this.element.setCustomValidity("");
    }
  }
}

