import { Controller } from "stimulus";
import { enter, leave } from "el-transition";
import { deleteCookie } from "../utils/cookies";

export default class extends Controller {
  static targets = ["slideover", "overlay", "panel", "title", "tabs", "headericon", "body"];

  connect() {
    this.opened = false;
    this.overlayTarget.addEventListener("mousedown", (ev) => {
      if (ev.target === ev.currentTarget) this.close.bind(this)();
    });
    this.element.addEventListener("slideover:close", () => {
      this.close();
    });
  }

  disconnect() {
    this.close();
  }

  populate(title, body, tabs, headericon, append = false) {
    this.titleTarget.innerHTML = title;
    this.headericonTarget.classList.add(`ph-${headericon}`);

    if (append) {
      let newContentDiv = document.createElement("div");
      newContentDiv.innerHTML = body;
      this.bodyTarget.appendChild(newContentDiv);
    } else {
      this.bodyTarget.innerHTML = body;
    }

    this.populateTabs(tabs);
  }

  populateTabs(tabs) {
    if (tabs.length == 0) {
      this.tabsTarget.classList.add("hidden");
    } else {
      this.tabsTarget.innerHTML = "";
      tabs.forEach((tab) => {
        const link = document.createElement("a");
        link.href = tab.href;
        link.innerHTML = tab.text;
        link.classList.add("slideover-tabs-tab");
        if (tab.active) link.classList.add("slideover-tabs-tab--active");
        if (tab.id !== undefined) link.id = tab.id;
        link.dataset.method = "get";
        this.tabsTarget.insertAdjacentElement("beforeEnd", link);
      });
      this.tabsTarget.classList.remove("hidden");
    }
  }

  open() {
    if (this.opened) return;

    this.show();
    enter(this.overlayTarget);
    enter(this.panelTarget);

    document.querySelector("body").style.overflowY = "hidden";

    window.closeSlideover = this.close.bind(this);

    this.opened = true;
  }

  close({ force = false } = {}) {
    if (this.preventCloseWhenAutosaveWithError() && !force) return;
    if (!this.opened) return;

    deleteCookie("open_organization_edit");
    deleteCookie("open_person_edit");

    document.dispatchEvent(new CustomEvent("slideover:closing"));

    leave(this.overlayTarget);
    leave(this.panelTarget);

    document.querySelector("body").style.overflowY = "auto";

    this.slideoverTarget.scrollTop = 0;

    setTimeout(this.hide.bind(this), 1000);

    this.opened = false;
  }

  forceClose() {
    this.close({ force: true });
  }

  show() {
    this.element.classList.remove("hidden");
  }

  hide() {
    this.element.classList.add("hidden");
  }

  preventCloseWhenAutosaveWithError() {
    const form = this.element.querySelector("form");
    if (!form) return false;

    return form.dataset.controller === "form-autosave" && !form.checkValidity();
  }
}
